/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Util } from "Util";
import axios from "axios";
import { AdminOrder, OrderRequest } from "models/Admin";
import { AppResponse } from "models/Response";
import { User } from "models/User";
import { Driver, UpdateDriverRequest } from "models/Driver";
import { Order } from "models/Order";
import { OrderStatus } from "enum/OrderStatus";
import { BillingUsers, Invoice } from "models/Invoice";
import { DriverList } from "models/DriverList";
import { AddressBook } from "models/AddressBook";
import { OrderResponse } from "models/orderResponse";
import { UserList } from "models/UserList";
import { OrderList } from "models/OrderList";
import { Setting } from "models/Setting";
import { DriverCommission, DriverCommissionList } from "models/DriverCommission";
import { QuoteModel } from "models/Quote";
import { FinanceIdResponse, FinanceListResponse, FinanceSummaryData, UpdateFinanceData } from "models/Finance";
import { AllDispachCount } from "models/AllDispachCount";
import { BillingList } from "models/BillingList";

export class AdminService {
  /* CUSTOMERS */
  public static async getCustomerList(limit?: number, offset?: number): Promise<AppResponse<UserList>> {
    const ep = Util.apiAuthUrl(`admin/user/list/` + offset + "/" + limit);
    const res = await axios.get<void, AppResponse<UserList>>(ep);
    return res;
  }

  public static async getCustomerById(userId?: string): Promise<AppResponse<User>> {
    const ep = Util.apiAuthUrl(`user/` + userId);
    const res = await axios.get<void, AppResponse<User>>(ep);
    return res;
  }

  public static async createCustomer(customer: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/user/create");
    const res = await axios.post<User, AppResponse<string>>(ep, customer);
    return res;
  }

  public static async updateCustomer(customer: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/user/update");
    const res = await axios.post<User, AppResponse<string>>(ep, customer);
    return res;
  }

  public static async updateCustomerData(customer: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/user/update-data");
    const res = await axios.post<User, AppResponse<string>>(ep, customer);
    return res;
  }

  public static async updateBusinessCustomer(customer: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/user/operating-hours");
    const res = await axios.post<User, AppResponse<string>>(ep, customer);
    return res;
  }

  /* ORDERS */
  public static async createOrder(order: AdminOrder): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/order/create");
    const res = await axios.post<AdminOrder, AppResponse<string>>(ep, order);
    return res;
  }

  public static async orderDetails(order: AdminOrder): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/order/details");
    const res = await axios.post<AdminOrder, AppResponse<string>>(ep, order);
    return res;
  }

  public static async getOrdersByStatus(data: any, status: OrderStatus, limit?: number, offset?: number): Promise<AppResponse<OrderList>> {
    const ep = Util.apiAuthUrl(`admin/order/list/` + status + "/" + offset + "/" + limit);
    return await axios.post<void, AppResponse<OrderList>>(ep, data);
  }

  // public static async getOrderdispachsBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<Order[]>> {
  //   const ep = Util.apiAuthUrl(`admin/dispachlistbysearch/` + offset + "/" + limit);
  //   return await axios.post<void, AppResponse<Order[]>>(ep, data);
  // }

  public static async getOrderdispachsBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<OrderList>> {
    const ep = Util.apiAuthUrl(`admin/dispachlistbysearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<OrderList>>(ep, data);
  }

  public static async getUsersBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<User[]>> {
    const ep = Util.apiAuthUrl(`admin/getUserBySearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<User[]>>(ep, data);
  }

  public static async getDriverBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<User[]>> {
    const ep = Util.apiAuthUrl(`admin/getDriverBySearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<User[]>>(ep, data);
  }

  public static async getOrderById(orderId?: string): Promise<AppResponse<OrderResponse>> {
    const ep = Util.apiAuthUrl(`admin/order/` + orderId);
    const res = await axios.get<void, AppResponse<OrderResponse>>(ep);
    return res;
  }

  public static async getOrderByZuluId(orderId?: string): Promise<AppResponse<OrderResponse>> {
    const ep = Util.apiAuthUrl(`admin/zuluorder/` + orderId);
    const res = await axios.get<void, AppResponse<OrderResponse>>(ep);
    return res;
  }

  public static async searchOrders(uniqueId: string, limit?: number, offset?: number): Promise<AppResponse<AdminOrder[]>> {
    const ep = Util.apiAuthUrl(`admin/order/search/` + uniqueId + "/" + offset + "/" + limit);
    return await axios.get<void, AppResponse<AdminOrder[]>>(ep);
  }

  public static async updateOrderValues(uniqueId: string, data: OrderRequest): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/order/update/" + uniqueId);
    const res = await axios.post<OrderRequest, AppResponse<string>>(ep, data);
    return res;
  }

  public static async updateOrderStatus(data: OrderRequest): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("admin/order/status");
    const res = await axios.post<OrderRequest, AppResponse<any>>(ep, data, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
  }

  public static async updateMultipleOrderStatus(data: OrderRequest): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("admin/order/multipleStatus");
    const res = await axios.post<OrderRequest, AppResponse<any>>(ep, data, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
  }

  public static async cancelOrder(data: OrderRequest): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("admin/order/status/cancel");
    const res = await axios.post<OrderRequest, AppResponse<any>>(ep, data);
    return res;
  }

  /* DRIVERS */
  public static async getDrivers(limit?: number, offset?: number): Promise<AppResponse<DriverList>> {
    const url = Util.apiAuthUrl(`admin/driver/list/` + offset + "/" + limit);
    return await axios.get<void, AppResponse<DriverList>>(url);
  }
  public static async getDriversSearch(data?: any, limit?: number, offset?: number): Promise<AppResponse<DriverList>> {
    const ep = Util.apiAuthUrl(`admin/driver/search/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<DriverList>>(ep, data);
  }

  public static async getDispatch(limit?: number, offset?: number): Promise<AppResponse<UserList>> {
    const url = Util.apiAuthUrl(`admin/dispatch/list/` + offset + "/" + limit);
    return await axios.get<void, AppResponse<UserList>>(url);
  }

  public static async getDispatchSearch(data?: any, limit?: number, offset?: number): Promise<AppResponse<User[]>> {
    const ep = Util.apiAuthUrl(`admin/dispatch/search/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<User[]>>(ep, data);
  }

  public static async postDriverByOrder(data: UpdateDriverRequest): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/order/driver");
    const res = await axios.post<UpdateDriverRequest, AppResponse<string>>(ep, data);
    return res;
  }

  public static async getDriverById(userId: string): Promise<AppResponse<Driver>> {
    const ep = Util.apiAuthUrl(`admin/driver/` + userId);
    const res = await axios.get<void, AppResponse<Driver>>(ep);
    return res;
  }

  public static async createDriver(driver: Driver): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/driver/create");
    const res = await axios.post<Driver, AppResponse<string>>(ep, driver);
    return res;
  }

  public static async updateDriver(driver: Driver): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/driver/edit");
    const res = await axios.post<Driver, AppResponse<string>>(ep, driver);
    return res;
  }

  public static async deleteDriverById(driverId: any): Promise<AppResponse<Driver>> {
    const ep = Util.apiAuthUrl(`admin/driver/delete/${driverId}`);
    const res = await axios.delete<void, AppResponse<Driver>>(ep);
    return res;
  }

  public static async deleteDispatchById(dispatchId: any): Promise<AppResponse<Driver>> {
    const ep = Util.apiAuthUrl(`admin/dispatch/delete/${dispatchId}`);
    const res = await axios.delete<void, AppResponse<Driver>>(ep);
    return res;
  }

  /* FINANCE */
  public static async getCustomerBilling(data: any): Promise<AppResponse<Invoice[]>> {
    const ep = Util.apiAuthUrl("admin/billing/customer-list");
    const res = await axios.post<void, AppResponse<Invoice[]>>(ep, data);
    return res;
  }

  public static async getCustomerBillingBySearch(data: any): Promise<AppResponse<Invoice[]>> {
    const ep = Util.apiAuthUrl("admin/billing/search/customer-list");
    return await axios.post<void, AppResponse<Invoice[]>>(ep, data);
  }

  // public static async getBusinessCustomerBilling(data: any): Promise<AppResponse<Invoice[]>> {
  //   const ep = Util.apiAuthUrl("admin/billing/business-customer-list");
  //   const res = await axios.post<void, AppResponse<Invoice[]>>(ep, data);
  //   return res;
  // }
  
  public static async getBusinessCustomerBilling(data: any,limit?: number, offset?: number): Promise<AppResponse<BillingList>> {
    const ep = Util.apiAuthUrl("admin/billing/business-customer-list/" + offset + "/" + limit);
    const res = await axios.post<void, AppResponse<BillingList>>(ep, data);

    return res;
  }

  public static async getBusinessCustomerInvoices(data: any, limit?: number, offset?: number): Promise<AppResponse<BillingUsers>> {
    const ep = Util.apiAuthUrl("admin/billing/business-customer-invoices/" + offset + "/" + limit);
    const res = await axios.post<void, AppResponse<BillingUsers>>(ep, data);
    return res;
  }

  public static async getBusinessCustomerInvoicesBySearch(data: any): Promise<AppResponse<BillingUsers>> {
    const ep = Util.apiAuthUrl("admin/billing/search/business-customer-invoices");
    const res = await axios.post<void, AppResponse<BillingUsers>>(ep, data);
    return res;
  }

  public static async sendBusinessCustomerInvoices(data: any): Promise<AppResponse<Invoice[]>> {
    const ep = Util.apiAuthUrl("admin/billing/send-business-Customer-invoices");
    const res = await axios.post<void, AppResponse<Invoice[]>>(ep, data);
    return res;
  }

  public static async getInvoiceById(invoiceId: string): Promise<AppResponse<Invoice>> {
    const ep = Util.apiAuthUrl(`admin/billing/` + invoiceId);
    const res = await axios.get<void, AppResponse<Invoice>>(ep);
    return res;
  }

  public static async genarateInvoicePDF(invoiceId?: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`admin/billing/pdf/` + invoiceId);
    const res = await axios.get<Invoice, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async getDriverBilling(data: any): Promise<AppResponse<DriverCommission[]>> {
    const ep = Util.apiAuthUrl("admin/billing/driver-list");
    const res = await axios.post<void, AppResponse<DriverCommission[]>>(ep, data);
    return res;
  }

  public static async getAllDriverBilling(data: any, limit?: number, offset?: number): Promise<AppResponse<DriverCommissionList>> {
    const ep = Util.apiAuthUrl("admin/billing/all-driver-list" + "/" + offset + "/" + limit);
    const res = await axios.post<void, AppResponse<DriverCommissionList>>(ep, data);
    return res;
  }

  public static async getAllDriverOldBilling(data: any, limit?: number, offset?: number): Promise<AppResponse<DriverCommissionList>> {
    const ep = Util.apiAuthUrl("admin/billing/old-driver-billing-list" + "/" + offset + "/" + limit);
    const res = await axios.post<void, AppResponse<DriverCommissionList>>(ep, data);
    return res;
  }

  public static async generateDriverBillPDF(data: any): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl("admin/driver-billing/pdf");
    const res = await axios.post<DriverCommission, AppResponse<ArrayBuffer>>(ep, data, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async sendDriverBillingInvoices(data: any): Promise<AppResponse<DriverCommission[]>> {
    const ep = Util.apiAuthUrl("admin/billing/send-driver-invoices");
    const res = await axios.post<void, AppResponse<DriverCommission[]>>(ep, data);
    return res;
  }

  public static async getOldDriverBilling(driverId?: string, limit?: number, offset?: number): Promise<AppResponse<DriverCommissionList>> {
    const url = Util.apiAuthUrl(`admin/billing/driver-old-list/` + driverId + "/" + offset + "/" + limit);
    return await axios.get<void, AppResponse<DriverCommissionList>>(url);
  }

  public static async getDriverBillingBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<DriverCommission[]>> {
    const ep = Util.apiAuthUrl(`admin/billing/search-list/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<DriverCommission[]>>(ep, data);
  }

  public static async generateOldDriverBillsPDF(driverId?: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`admin/driver-old-billings/pdf/` + driverId);
    const res = await axios.post<DriverCommission, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  /* ADDRESS BOOKS */
  public static async getCustomerAddressBooks(data: any): Promise<AppResponse<AddressBook[]>> {
    const ep = Util.apiAuthUrl("admin/address-book/list");
    const res = await axios.post<void, AppResponse<AddressBook[]>>(ep, data);
    return res;
  }

  /* SETTINGS */
  public static async getSettings(): Promise<AppResponse<Setting>> {
    const ep = Util.apiAuthUrl("admin/setting/list");
    const res = await axios.get<void, AppResponse<Setting>>(ep);
    return res;
  }

  public static async updateSettings(data: Setting): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/setting/edit");
    const res = await axios.post<Setting, AppResponse<string>>(ep, data);
    return res;
  }

  /* QUOTE - PUBLIC ROUTE */
  public static async generateQuote(data: any): Promise<AppResponse<QuoteModel>> {
    const ep = Util.apiPublicUrl("quote/create");
    const res = await axios.post<void, AppResponse<QuoteModel>>(ep, data);
    return res;
  }

  /*finance  */

  public static async getAllFinance(limit?: number, offset?: number): Promise<AppResponse<FinanceListResponse>> {
    const url = Util.apiAuthUrl(`admin/finance/list/${offset}/${limit}`);
    return await axios.get<void, AppResponse<FinanceListResponse>>(url);
  }

  public static async getFinanceBySearch(data?: any, limit?: number, offset?: number): Promise<AppResponse<FinanceListResponse>> {
    const ep = Util.apiAuthUrl(`admin/Financelistbysearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<FinanceListResponse>>(ep, data);
  }

  public static async getFinanceByDate(data?: any, limit?: number, offset?: number): Promise<AppResponse<FinanceListResponse>> {
    const ep = Util.apiAuthUrl(`admin/FinancelistbyDate/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<FinanceListResponse>>(ep, data);
  }

  public static async getFinanceModal(userId?: string, limit?: number, offset?: number): Promise<AppResponse<FinanceIdResponse>> {
    const ep = Util.apiAuthUrl(`admin/FinanceModal/${userId}/${offset}/${limit}`);
    return await axios.post<void, AppResponse<FinanceIdResponse>>(ep);
  }

  public static async getFinanceModalBySearch(
    data?: any,
    userId?: string,
    limit?: number,
    offset?: number
  ): Promise<AppResponse<FinanceIdResponse>> {
    const ep = Util.apiAuthUrl(`admin/FinanceModalbysearch/${userId}/${offset}/${limit}`);
    return await axios.post<void, AppResponse<FinanceIdResponse>>(ep, data);
  }

  public static async getFinanceModalByDate(
    data?: any,
    userId?: string,
    limit?: number,
    offset?: number
  ): Promise<AppResponse<FinanceIdResponse>> {
    const ep = Util.apiAuthUrl(`admin/FinanceModalbyDate/${userId}/${offset}/${limit}`);
    return await axios.post<void, AppResponse<FinanceIdResponse>>(ep, data);
  }

  public static async getalldispachcount(): Promise<AppResponse<AllDispachCount>> {
    const url = Util.apiAuthUrl(`admin/order/getalldispachcount`);
    return await axios.post<void, AppResponse<AllDispachCount>>(url);
  }

  public static async sendInvoiceEmail(invoiceId?: string, data?: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`admin/send-invoice-email/pdf/` + invoiceId);
    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }

  public static async sendSelectedPaymentReminderEmail(customerIds?: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`admin/send-selected-payment-reminder-email/pdf/`);
    const res = await axios.post<void, AppResponse<any>>(ep, customerIds);
    return res;
  }

  public static async sendSelectedInvoicesEmail(data?: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`admin/send-selected-invoices-email/pdf/`);
    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }

  public static async DispatchUserSignup(userSignupData: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/user/create");
    return await axios.post<User, AppResponse<string>>(ep, userSignupData);
  }

  public static async getFinanceSummary(): Promise<AppResponse<FinanceSummaryData>> {
    const url = Util.apiAuthUrl(`admin/finance/summary`);
    const response = await axios.get<AppResponse<FinanceSummaryData>>(url);
    return response.data;
  }

  public static async getFinancePastInvoice(): Promise<AppResponse<FinanceSummaryData>> {
    const url = Util.apiAuthUrl(`admin/finance/summary`);
    const response = await axios.get<AppResponse<FinanceSummaryData>>(url);
    return response.data;
  }

  public static async genarateFianancePDF(invoiceId: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`finance/pdf/${invoiceId}`);
    const res = await axios.get<Order, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async sendDriverInvoiceEmail(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("admin/send-driver-invoice-email/pdf");
    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }

  public static async updatePaymentDetails(data: UpdateFinanceData): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("admin/finance/edit");
    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }

  // public static async postEdit(
  //   editInvoiceId?: string,
  //   editNewAmount?: number,
  //   editNewDiscount?: number,
  //   editNewPayment?: string,
  //   editNewStatus?: string
  // ): Promise<AppResponse<FinanceIdResponse>> {
  //   const ep = Util.apiAuthUrl("admin/FinancePostEdit"); // Assuming the endpoint is correct
  //   const requestData = {
  //     editInvoiceId,
  //     editNewAmount,
  //     editNewDiscount,
  //     editNewPayment,
  //     editNewStatus,
  //   };

  //   return await axios.post<void, AppResponse<FinanceIdResponse>>(ep, requestData);
  // }

  public static async getPendingSearchUsers(data?: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`admin/getPendingUserBySearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<any>>(ep, data);
  }

  public static async updateUserStatus(customer: User): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("admin/updateUserStatus");
    const res = await axios.post<User, AppResponse<string>>(ep, customer);
    return res;
  }
}

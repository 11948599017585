import { AddressModel } from "./City";

export enum PaymentStatus {
  PENDING = "pending",
  CANCELED = "canceled",
  PAID = "paid",
  FAILED = "failed",
  NOT_REQUIRED = "not_required",
}

export interface OrderResponse {
  _id: string;
  pickUpAddress?: AddressModel;
  deliveryAddress?: AddressModel;
  totalDistance?: string;
  subTotal?: number;
  totalWeight?: string;
  hst?: number;
  paymentStatus?: PaymentStatus;
  orderStatus?: string;
  deliveryOption?: string;
  deliveryPin?:string;
  driverName?:string;
  totalFare?: number;
  totalItems?: string;
  packageReadyTime: Date;
  deliveryTime?: Date;
  vehicleType?: string;
  userId?: string;
  uniqueId?: string;
  notes?: string;
  description?: string;
  createdAt?: string;
  sentBy?: string;
  statusDetails?: {
    date?: string;
    notes: string;
    receiverName: string;
    shipperName: string;
    status: string;
    time: string;
    image: string;
    imageData: string;
  };
}

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import download from "../../assets/images/download.svg";
import { OrderService } from "services/OrderService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { RequestState } from "enum/RequestState";
import OrderStatusModal from "modals/OderDetailsModal";
import { Tooltip } from "react-tooltip";
import { environment } from "environment/environment";

import "../../assets/css/custom.css";
import "../../assets/css/table.css";
import { nb } from "date-fns/esm/locale";
import ReactPaginate from "react-paginate";
import Select from "react-select";

const headers = ['Head1', 'Head2', 'Head3', 'Head4'];
const tableData = [
  { Head1: '1', Head2: '2', Head3: '3', Head4: '4' },
  { Head1: '5', Head2: '6', Head3: '7', Head4: '8' },
  // Add more rows as needed
];

function HeaderFields({
  preGlobalFilteredRows,
  globalFilter,
  handleSearch,
  selectedColumns,
  handleColumnChange,
  showColumnChange,
  isJobListGlobalFilter,
  isAddressBook,
  selectedFlatRows,
  hancleDeleteRecords,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const options = [
    { value: 10, label: "10 Rows" },
    { value: 15, label: "15 Rows" },
    { value: 20, label: "20 Rows" },
    { value: 25, label: "25 Rows" },
    { value: 50, label: "50 Rows" },
  ];

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
          {isAddressBook && (
            <button
              className="btn btn-primary bx bx-trash btn-sm px-5 me-lg-2 me-md-2 mb-2 mt-lg-1 mt-md-1 h-100"
              disabled={selectedFlatRows.length < 1}
              onClick={() => hancleDeleteRecords(selectedFlatRows)}
            ></button>
          )}
          <div className="search-box">
            <div className="position-relative">
              <label htmlFor="search-bar-0 " className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control border border-light-subtle rounded-3 py-2 px-5"
                  placeholder={`Search ..`}
                  value={value || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          {showColumnChange && (
            <div className="search-box ps-lg-2 ps-md-2">
              <Select options={options} value={options.find(option => option.value === selectedColumns)} onChange={handleColumnChange} />
            </div>
          )}
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  );
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const TableContainer = ({
  columns,
  data,
  currentPage,
  pagelimit,
  handlePageChange,
  handleSearch,
  handleColumnChange,
  selectedColumns,
  showColumnChange,
  ispopup,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  isPDFGenerate,
  isAddressBook,
  hancleDeleteRecords,
  handleSelect,
  selectedID,
  hasCheckBox,
  handleSendEmail,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      isAddressBook &&
        hooks.visibleColumns.push(columns => [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),

            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );
  const [orderId, setOrderId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [checkboxCounter, setCheckboxCounter] = useState(0);
  const [isTick, setIsTick] = useState(false);

  const handleCheckboxCheck = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }

    setCheckboxCounter(prevCounter => prevCounter + 1);
    setIsTick(true);
  };
  const handleCheckboxUnCheck = id => {
    setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    setCheckboxCounter(prevCounter => prevCounter - 1);
  };

  React.useEffect(() => {
    setIsTick(checkboxCounter > 0);
  }, [checkboxCounter]);

  const generateOrderPdf = orderid => {
    OrderService.genarateOrderPDF(orderid).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const generateShippingLabelPdf = orderid => {
    OrderService.generateShippingLabelPDF(orderid).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };
  // const generateOrderPdf = async orderid => {
  //   OrderService.genarateOrderPDF(orderid).then(res => {
  //     if (res) {
  //       try {
  //         // const pdfDataUri = `data:application/pdf;base64,${atob(res)}`;
  //         // const pdfBlob = new Blob([res], { type: "application/pdf" });
  //         // const pdfURL = URL.createObjectURL(pdfBlob);
  //         window.open(res.pdfUrl);
  //       } catch {
  //         toast.error("Something went wrong while downloading. Please try again later.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: "foo-bar",
  //           style: {marginBottom: "4rem"},
  //         });
  //       }
  //     } else {
  //       toast.error("Connection error. Please try again later.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: "foo-bar",
  //         style: {marginBottom: "4rem"},
  //       });
  //     }
  //   });
  // };

  const generateSortingIndicator = column => {
    //return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
    return column.isSortedDesc ? " ⇩" : " ⇧";
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const toggleOrderModalInput = id => {
    setOrderId(id);
    setIsClicked(isClicked => {
      if (!isClicked) {
        handleModal();
      }
      return !isClicked;
    });
  };

  // const getAddressCity = async address => {
  //   let city;
  //   const res = await Geocode.fromAddress(address);
  //   const add = res.results[0].address_components;
  //   for (let i = 0; i < add.length; i++) {
  //     for (let j = 0; j < add[i].types.length; j++) {
  //       if (add[i].types[j] === "locality") {
  //         city = add[i].long_name;
  //       }
  //     }
  //   }
  //   return city;
  // };

  // function GetCity({ cityAddress }) {
  //   const [value, setValue] = React.useState("");
  //   getAddressCity(cityAddress).then(city => {
  //     setValue(city);
  //   });
  //   return value;
  // }

  return (
    <Fragment>
      <OrderStatusModal isOpen={isClicked} handleIsOpen={handleModal} orderId={orderId} getPDF={generateOrderPdf} />
      <Row className="d-flex flex-row justify-content-end pb-2 pb-md-1">
        <HeaderFields
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          handleColumnChange={handleColumnChange}
          selectedColumns={selectedColumns}
          showColumnChange={showColumnChange}
          handleSearch={handleSearch}
          isJobListGlobalFilter={isJobListGlobalFilter}
          isAddressBook={isAddressBook}
          selectedFlatRows={selectedFlatRows}
          hancleDeleteRecords={hancleDeleteRecords}
        />

        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleOrderClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded mb-2 me-2 btn-sm" onClick={handleCustomerClick}>
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <table bordered hover {...getTableProps()} className="newTable">
          <thead className="newThead">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {hasCheckBox && <th className="text-center pb-4"></th>}
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.render("Header") === "Weight (lbs)" ? "text-wrap" : "text-nowrap"}>
                    <div className="d-flex flex-row justify-content-center" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.accessor !== undefined ? generateSortingIndicator(column) : ""}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
                {isPDFGenerate && <th className="text-center pb-4"></th>}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.reverse().map((row, i) => {
              prepareRow(row);
              const isRowSelected = selectedIds.includes(row.original._id);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr key={i}>
                    {hasCheckBox && (
                      <td>
                        <Input
                          type="checkbox"
                          checked={isRowSelected}
                          onChange={event => {
                            if (event.target.checked) {
                              handleCheckboxCheck(row.original._id);
                            } else {
                              handleCheckboxUnCheck(row.original._id);
                            }
                          }}
                        />
                      </td>
                    )}
                    {row.cells.map(cell => {
                      const dataLabel = cell.column.Header?.props?.children ? cell.column.Header?.props?.children : cell.column.Header;
                      if (ispopup) {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            data-label={dataLabel}
                            onClick={() => (cell.column.Header === "Actions" ? "" : toggleOrderModalInput(row.original._id))}
                            style={{ cursor: "pointer" }}
                          >
                            <div data-tooltip-id={cell.column.Header + row.original._id}>{cell.render("Cell")}</div>
                            {(cell.column.Header === "Pickup" || cell.column.Header === "Delivery") && (
                              <Tooltip
                                id={cell.column.Header + row.original._id}
                                content={
                                  cell.column.Header === "Pickup" ? row.original.pickUpAddress.city : row.original.deliveryAddress.city
                                }
                                style={{ backgroundColor: "#FFEDE5", color: "#495057", width: 200 }}
                              />
                            )}
                          </td>
                        );
                      } else if (selectedID !== undefined) {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            data-label={dataLabel}
                            onClick={() => handleSelect(row.original._id)}
                            style={{ padding: "0px", cursor: "pointer", hover: "#F75D21" }}
                          >
                            <div className={selectedID === row.original._id ? "custom-div custom-div-new" : "custom-div"}>
                              {cell.render("Cell")}
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                            <div>{cell.render("Cell")}</div>
                          </td>
                        );
                      }
                    })}
                    {isPDFGenerate && (
                      <td className="px-1" data-label=''>
                        {" "}
                        <span className="download_btn_container">
                          <img className="large_screen_download" src={download} alt="" onClick={() =>  generateShippingLabelPdf(row.original._id)} />
                          <button className="btn btn-primary small_screen_download sm-custom-button" onClick={() => generateShippingLabelPdf(row.original._id)}>Download</button>
                        </span>
                      </td>
                    )}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>

      </div>
      {/* <div >
        <table className="newTable">
          <thead className="newThead">
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, cellIndex) => (
                  <td key={cellIndex} data-label={header}>
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      <Row>
        <Col lg={2}>
          {hasCheckBox && (
            <div>
              <button type="submit" disabled={!isTick} onClick={() => handleSendEmail(selectedIds)} className="btn btn-primary btn-sm py-2">
                Email Invoices
              </button>
            </div>
          )}
        </Col>
        <Col lg={10}>
          {pagelimit > 1 && (
            <ReactPaginate
              pageCount={pagelimit}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="pagination justify-content-lg-end align-items-lg-end rounded-pill px-3"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;

import React, { useEffect, useState } from "react";
import { Routes, Route} from "react-router-dom";
import { layoutTypes } from "./constants/layout";

import Authmiddleware from "./routes/authmiddleware";
import VerticalLayout from "./components/VerticalLayout";

import HorizontalLayout from "./components/HorizontalLayout";
import "./assets/scss/theme.scss";
import fakeBackend from "./helpers/AuthType/fakeBackend";
import Signin from "pages/Signin/signin";
import "./assets/css/custom.css";
import { UserTypes } from "enum/UserTypes";

//pages
import CurrentOrders from "pages/Orders/CurrentOrders";
import FutureOrders from "pages/Orders/FutureOrders";
import DeliveredOrders from "pages/Orders/DeliveredOrders";
import CanceledOrders from "pages/Orders/CanceledOrders";
import AddressBook from "pages/AddressBook/AddressBook";
import Invoices from "pages/Invoices/Invoices";
import PaymentLogs from "pages/PaymentLogs/PaymentLogs";
import Pages404 from "components/Common/NotFound";
import Signup from "pages/Signup/signup";
import PaymentSuccess from "components/Common/PaymentSuccess";
import PaymentFailed from "components/Common/PaymentFailed";
import PlaceOrdersBusiness from "pages/PlaceOrders/PlaceOrdersBusiness";
import EmailVerification from "pages/EmailVerification/emailVerification";
import PrivateRoute from "components/Common/PrivateRouteComponent/PrivateRoute";
import FailedOrders from "pages/Orders/FailedOrders";
import ForgetPassword from "pages/ForgetPassword/forgetPassword";
import PasswordReset from "pages/ForgetPassword/passwordReset";
import ResetVerification from "pages/ForgetPassword/resetVerification";
import Users from "admin/PlaceOrders/Users";
import { AuthService } from "services/AuthService";
import { AuthData, useAuth } from "context/AuthProvider";
import ManageCustomers from "admin/ManageCustomers/ManageCustomers";
import AdminLiveOrders from "admin/dispatch/adminLiveOrders/AdminLiveOrders";
import AdminDraftOrders from "admin/dispatch/adminDraftOrders/AdminDradtOrders";
import AdminCurrentOrders from "admin/dispatch/adminCurrentOrders/AdminCurrentOrders";
import AdminCanceledOrders from "admin/dispatch/adminCanceledOrders/AdminCanceledOrders";
import AdminDeliveredOrders from "admin/dispatch/adminDeliveredOrders/AdminDeliveredOrders";
import ManageDrivers from "admin/manageDrivers/ManageDrivers";
import ManageServices from "admin/manageOrders/manageService/ManageServices";
import AdminPickupOrders from "admin/dispatch/adminPickupOrders/AdminPickupOrders";
import AdminFaildPickupOrders from "admin/dispatch/adminFaildPickupOrders/AdminFaildPickupOrders";
import AdminFutureOrders from "admin/dispatch/adminFutureOrders/AdminFutureOrders";
import AdminReturnOrders from "admin/dispatch/adminReturnOrders/AdminReturnOrders";
import AdminReattemptOrders from "admin/dispatch/adminReattemptOrders/AdminReattemptOrders";
import CustomerBilling from "admin/CustomerBilling/CustomerBilling";
import Profile from "pages/Profile/Profile";
import ChangePassword from "pages/Profile/ChangePassword";
import AdminBilledOrders from "admin/dispatch/adminBilledOrders/AdminBilledOrders";
import AdminDeliveredToday from "admin/dispatch/adminDeliveredToday/AdminDeliveredToday";
import { ImageData, useImage } from "context/ProfileImageProvider";
import DriverBilling from "admin/DriverBilling/DriverBilling";
import AdminFinance from "admin/Finance/Finance";
import Quote from "admin/Quote/Quote";
import DraftOrders from "pages/DraftOrders/DraftOrders";
import ReturnOrders from "pages/Orders/ReturnOrders";
import ReattemptOrders from "pages/Orders/ReattemptOrders";
import BilledOrders from "pages/Orders/BilledOrders";
import ViewAllNotification from "pages/Notification/Notification";
import ViewCustomerBilling from "admin/CustomerBilling/View Billing/ViewCustomerBilling";
import AddDispatchUser from "pages/Profile/AddDispatchUser";
import PaymentSuccessInvoice from "components/Common/PaymentSuccessInvoice";
import TermsOfService from "pages/TermsOfService/Index";
import DriverView from "admin/DriverBilling/View Billing/DriverView";
import PendingUsers from "admin/pendingUser/pendingUser";
import Landing from "pages/Landing";
import DriverOldBillingView from "admin/DriverBilling/DriverOldBillingView";

// Activating fake backend
fakeBackend();

const getLayout = (layoutType: string) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App: React.FC = () => {
  const Layout = getLayout(layoutTypes.VERTICAL);
  const [user, setUser] = useAuth() as AuthData;
  const [image, setImage] = useImage() as ImageData;
  const [isLoading, setIsloading] = useState<boolean>(true);
  const token = localStorage.getItem("token");
  const remember = localStorage.getItem("remember");
  const [isErr, setIsErr] = useState<boolean>(false);
  useEffect(() => {
    AuthService.getMe()
      .then(res => {
        if (res.success) {
          setUser(res.data);
          setIsErr(false);
        } else {
          setIsErr(true);
        }
        setIsloading(false);
      })
      .catch(error => {
        setIsloading(false);
      });
  }, []);

  useEffect(() => {
    AuthService.getProfileImage(user?.photoId).then(res => {
      if (res.success !== false) {
        setImage({ ...image, dataURL: `data:image/*;base64,${res}` });
      }
    });
  }, [user]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/signin" element={
             (user?.role == UserTypes.PERSONAL_CUSTOMER  ||user?.role == UserTypes.BUSINESS_CUSTOMER) && remember == "Yes" ? (
              <Layout>{<PlaceOrdersBusiness />}</Layout>
            ) :<Signin />
          }> </Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/password-reset" element={<PasswordReset />}></Route>
        <Route path="/reset-verification/:id" element={<ResetVerification />}></Route>
        <Route
          path="/email-verification"
          element={
            <PrivateRoute redirectTo="/signin">
              <EmailVerification />
            </PrivateRoute>
          }
        ></Route>

        <Route path="/quote" element={<Quote />}></Route>
        <Route path="/terms-of-service" element={<TermsOfService />}></Route>
        <Route path="/" element={<Landing />}></Route>
        
        <Route element={<Authmiddleware allowed={UserTypes.BUSINESS_CUSTOMER} />}>
          <Route path="/business_customer">
            <Route path="place-orders" element={<Layout>{<PlaceOrdersBusiness />}</Layout>} />
            <Route path="current-orders" element={<Layout>{<CurrentOrders />}</Layout>} />
            <Route path="future-orders" element={<Layout>{<FutureOrders />}</Layout>} />
            <Route path="delivered-orders" element={<Layout>{<DeliveredOrders />}</Layout>} />
            <Route path="billed-orders" element={<Layout>{<BilledOrders />}</Layout>} />
            <Route path="canceled-orders" element={<Layout>{<CanceledOrders />}</Layout>} />
            <Route path="failed-orders" element={<Layout>{<FailedOrders />}</Layout>} />
            <Route path="return-orders" element={<Layout>{<ReturnOrders />}</Layout>} />
            <Route path="reattempt-orders" element={<Layout>{<ReattemptOrders />}</Layout>} />
            <Route path="address-book" element={<Layout>{<AddressBook />}</Layout>} />
            <Route path="invoices" element={<Layout>{<Invoices />}</Layout>} />
            <Route path="payment-logs" element={<Layout>{<PaymentLogs />}</Layout>} />
            <Route path="profile-settings" element={<Layout>{<Profile />}</Layout>}></Route>
            <Route path="change-password" element={<Layout>{<ChangePassword />}</Layout>}></Route>
            <Route path="payment-success/:order_id" element={<PaymentSuccess />} />
            <Route path="payment-success/invoice/:invoice_id" element={<PaymentSuccessInvoice />} />
            <Route path="payment-error" element={<PaymentFailed />} />
            <Route path="notification" element={<Layout>{<ViewAllNotification />}</Layout>} />
          </Route>
        </Route>

        <Route element={<Authmiddleware allowed={UserTypes.PERSONAL_CUSTOMER} />}>
          <Route path="/personal_customer">
            <Route path="place-orders" element={<Layout>{<PlaceOrdersBusiness />}</Layout>} />
            <Route path="current-orders" element={<Layout>{<CurrentOrders />}</Layout>} />
            <Route path="future-orders" element={<Layout>{<FutureOrders />}</Layout>} />
            <Route path="delivered-orders" element={<Layout>{<DeliveredOrders />}</Layout>} />
            <Route path="billed-orders" element={<Layout>{<BilledOrders />}</Layout>} />
            <Route path="canceled-orders" element={<Layout>{<CanceledOrders />}</Layout>} />
            <Route path="failed-orders" element={<Layout>{<FailedOrders />}</Layout>} />
            <Route path="return-orders" element={<Layout>{<ReturnOrders />}</Layout>} />
            <Route path="reattempt-orders" element={<Layout>{<ReattemptOrders />}</Layout>} />
            <Route path="address-book" element={<Layout>{<AddressBook />}</Layout>} />
            <Route path="payment-logs" element={<Layout>{<PaymentLogs />}</Layout>} />
            <Route path="profile-settings" element={<Layout>{<Profile />}</Layout>}></Route>
            <Route path="change-password" element={<Layout>{<ChangePassword />}</Layout>}></Route>
            <Route path="payment-success/:order_id" element={<PaymentSuccess />} />
            <Route path="payment-error" element={<PaymentFailed />} />
            <Route path="draft-orders" element={<Layout>{<DraftOrders />}</Layout>} />
            <Route path="invoices" element={<Layout>{<Invoices />}</Layout>} />
            <Route path="payment-success/invoice/:invoice_id" element={<PaymentSuccessInvoice />} />
            <Route path="notification" element={<Layout>{<ViewAllNotification />}</Layout>} />
          </Route>
        </Route>

        <Route element={<Authmiddleware allowed={UserTypes.SUPER_ADMIN} />}>
          <Route path="/super_admin">
            <Route path="place-orders" element={<Layout>{<Users />}</Layout>} />
            <Route path="manage-customers" element={<Layout>{<ManageCustomers />}</Layout>} />
            <Route path="manage-drivers" element={<Layout>{<ManageDrivers />}</Layout>} />
            <Route path="live-orders" element={<Layout>{<AdminLiveOrders />}</Layout>} />
            <Route path="draft-orders" element={<Layout>{<AdminDraftOrders />}</Layout>} />
            <Route path="current-orders" element={<Layout>{<AdminCurrentOrders />}</Layout>} />
            <Route path="pick-up-orders" element={<Layout>{<AdminPickupOrders />}</Layout>} />
            <Route path="failed-pick-up-orders" element={<Layout>{<AdminFaildPickupOrders />}</Layout>} />
            <Route path="future-orders" element={<Layout>{<AdminFutureOrders />}</Layout>} />
            <Route path="delivered-orders" element={<Layout>{<AdminDeliveredOrders />}</Layout>} />
            <Route path="delivered-today" element={<Layout>{<AdminDeliveredToday />}</Layout>} />
            <Route path="billed-orders" element={<Layout>{<AdminBilledOrders />}</Layout>} />
            <Route path="return-orders" element={<Layout>{<AdminReturnOrders />}</Layout>} />
            <Route path="reattempt-orders" element={<Layout>{<AdminReattemptOrders />}</Layout>} />
            <Route path="canceled-orders" element={<Layout>{<AdminCanceledOrders />}</Layout>} />
            <Route path="manage-services" element={<Layout>{<ManageServices />}</Layout>} />
            <Route path="customer-billing" element={<Layout>{<CustomerBilling />}</Layout>} />
            <Route path="customer-billing-view" element={<Layout>{<ViewCustomerBilling />}</Layout>} />
            <Route path="profile-settings" element={<Layout>{<Profile />}</Layout>}></Route>
            <Route path="change-password" element={<Layout>{<ChangePassword />}</Layout>}></Route>
            <Route path="dispach-user" element={<Layout>{<AddDispatchUser />}</Layout>}></Route>
            <Route path="driver-billing" element={<Layout>{<DriverBilling />}</Layout>}></Route>
            <Route path="driver-billing-view" element={<Layout>{<DriverView />}</Layout>} />
            <Route path="driver-old-billing-view" element={<Layout>{<DriverOldBillingView />}</Layout>} />
            <Route path="finance" element={<Layout>{<AdminFinance />}</Layout>}></Route>
            <Route path="quote" element={<Layout>{<Quote />}</Layout>}></Route>
            <Route path="notification" element={<Layout>{<ViewAllNotification />}</Layout>} />
            <Route path="payment-success/:order_id" element={<PaymentSuccess />} />
            <Route path="payment-error" element={<PaymentFailed />} />
            <Route path="pending-users" element={<Layout>{<PendingUsers />}</Layout>} />
          </Route>
        </Route>

        <Route element={<Authmiddleware allowed={UserTypes.DISPATCH} />}>
          <Route path="/dispatch">
            <Route path="place-orders" element={<Layout>{<Users />}</Layout>} />
            <Route path="manage-customers" element={<Layout>{<ManageCustomers />}</Layout>} />
            <Route path="live-orders" element={<Layout>{<AdminLiveOrders />}</Layout>} />
            <Route path="draft-orders" element={<Layout>{<AdminDraftOrders />}</Layout>} />
            <Route path="current-orders" element={<Layout>{<AdminCurrentOrders />}</Layout>} />
            <Route path="pick-up-orders" element={<Layout>{<AdminPickupOrders />}</Layout>} />
            <Route path="failed-pick-up-orders" element={<Layout>{<AdminFaildPickupOrders />}</Layout>} />
            <Route path="future-orders" element={<Layout>{<AdminFutureOrders />}</Layout>} />
            <Route path="delivered-orders" element={<Layout>{<AdminDeliveredOrders />}</Layout>} />
            <Route path="delivered-today" element={<Layout>{<AdminDeliveredToday />}</Layout>} />
            <Route path="billed-orders" element={<Layout>{<AdminBilledOrders />}</Layout>} />
            <Route path="return-orders" element={<Layout>{<AdminReturnOrders />}</Layout>} />
            <Route path="reattempt-orders" element={<Layout>{<AdminReattemptOrders />}</Layout>} />
            <Route path="canceled-orders" element={<Layout>{<AdminCanceledOrders />}</Layout>} />
            <Route path="manage-services" element={<Layout>{<ManageServices />}</Layout>} />
            <Route path="profile-settings" element={<Layout>{<Profile />}</Layout>}></Route>
            <Route path="change-password" element={<Layout>{<ChangePassword />}</Layout>}></Route>
            <Route path="dispach-user" element={<Layout>{<AddDispatchUser />}</Layout>}></Route>
            <Route path="finance" element={<Layout>{<AdminFinance />}</Layout>}></Route>
            <Route path="quote" element={<Layout>{<Quote />}</Layout>}></Route>
            <Route path="notification" element={<Layout>{<ViewAllNotification />}</Layout>} />
            <Route path="payment-success/:order_id" element={<PaymentSuccess />} />
            <Route path="payment-error" element={<PaymentFailed />} />
          </Route>
        </Route>

        <Route path="/not-found" element={<Pages404 />}></Route>
        <Route path="*" element={<Pages404 />}></Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;

import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../Common/withRouter";
import { Link } from "react-router-dom";
import { AdminService } from "services/AdminService";
//i18n
import { withTranslation } from "react-i18next";
import { useAuth } from "context/AuthProvider";
import { useDispachCount } from "context/OrderCountProvider";
import { UserTypes } from "enum/UserTypes";

const SidebarContent = props => {
  const [user] = useAuth();
  const [dispachCount, setDispachCount] = useDispachCount();
  const ref = useRef();
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El = parent.childNodes && parent.childNodes.lenght && parent.childNodes[1] ? parent.childNodes[1] : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
        // console.log(res);
      }
    });
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (props.isMobile) {
      //body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      {(user?.role === "super_admin" || user?.role === "dispatch") && (
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="add-border sidebar-item">
                <Link 
                  to={`/${user?.role}/place-orders`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-shopping-bag"></i>
                  <span>{props.t("Place Orders")}</span>
                </Link>
              </li>

              <li  className="add-border sidebar-item">
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Dispatch")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/draft-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Draft Orders")}
                      {dispachCount?.draft != 0 ? (
                      
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.draft}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/live-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Live Orders")}
                      {dispachCount?.live != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.live}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/current-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Current Orders")}
                      {dispachCount?.current != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.current}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/billed-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Billed Orders")}
                      {dispachCount?.billed != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.billed}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/pick-up-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Picked-up Orders")}
                      {dispachCount?.picked != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.picked}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/failed-pick-up-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Failed Pick-up ")}
                      {dispachCount?.pickupfaild != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.pickupfaild}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/future-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Future Orders")}
                      {dispachCount?.future != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.future}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/delivered-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      {props.t("Delivered Orders")}
                      {dispachCount?.delivered != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.delivered}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/delivered-today`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      {props.t("Delivered Today")}
                      {dispachCount?.deliverdtoday != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.deliverdtoday}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/return-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Return Orders")}
                      {dispachCount?.returnOrders != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.returnOrders}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/reattempt-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Reattempt Orders")}
                      {dispachCount?.reattenpt != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.reattenpt}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/canceled-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Canceled Orders")}
                      {dispachCount?.canceled != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.canceled}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/manage-customers`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-group"></i>
                  <span>{props.t("Manage Customers")}</span>
                </Link>
              </li>
              <li className="add-border sidebar-item">
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-book"></i>
                  <span>{props.t("Manage Orders")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/manage-services`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Manage Service")}
                    </Link>
                  </li>
                </ul>
              </li>
              {user?.role === "super_admin" && (
                <>
                  <li className="add-border sidebar-item">
                    <Link
                      to={`/${user?.role}/manage-drivers`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      <i className="bx bx-user"></i>
                      <span>{props.t("Manage Drivers")}</span>
                    </Link>
                  </li>

                  <li className="add-border sidebar-item">
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-receipt"></i>
                      <span>{props.t("Customer Billing")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link className="remove-front d-flex justify-content-between align-items-center"
                          to={`/${user?.role}/customer-billing`}
                          onClick={() => {
                            tToggle();
                          }}
                        >
                          
                          <span>{props.t("Customer Billing")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="remove-front d-flex justify-content-between align-items-center"
                          to={`/${user?.role}/customer-billing-view`}
                          onClick={() => {
                            tToggle();
                          }}
                        >
                          
                          <span>{props.t("View All Invoices")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="add-border sidebar-item">
                    <Link to="/#" className="has-arrow">
                      <i className="bx bxs-receipt"></i>
                      <span>{props.t("Driver Billing")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link className="remove-front d-flex justify-content-between align-items-center"
                          to={`/${user?.role}/driver-billing`}
                          onClick={() => {
                            tToggle();
                          }}
                        >
                          
                          <span>{props.t("Driver Billing")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="remove-front d-flex justify-content-between align-items-center"
                          to={`/${user?.role}/driver-billing-view`}
                          onClick={() => {
                            tToggle();
                          }}
                        >
                          
                          <span>{props.t("View All Invoices")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="remove-front d-flex justify-content-between align-items-center"
                          to={`/${user?.role}/driver-old-billing-view`}
                          onClick={() => {
                            tToggle();
                          }}
                        >
                          
                          <span>{props.t("Old Invoices")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/finance`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-wallet-alt"></i>
                  <span>{props.t("Finance")}</span>
                </Link>
              </li>

              {/* <li>
                <Link to={`/super_admin/broadcast-massagers`} onClick={() => { tToggle(); }}>
                  <i className="bx bx-envelope"></i>
                  <span>{props.t("Broadcast Massagers")}</span>
                </Link>
              </li> */}

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/quote`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bxs-quote-left"></i>
                  <span>{props.t("Quote")}</span>
                </Link>
              </li>
              {
                user?.role === "super_admin" && (
                  <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/pending-users`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bxs-user"></i>
                  <span>{props.t("Non-Verified Users")}</span>
                </Link>
              </li>
                )
              }
            </ul>
          </div>
        </SimpleBar>
      )}

      {(user?.role === "business_customer" || user?.role === "personal_customer") && (
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
            
              <li className="add-border sidebar-item ">
                <Link
                  to={`/${user?.role}/place-orders`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-shopping-bag"></i>
                  <span>{props.t("Place Orders")}</span>
                </Link>
              </li>
              
              <li className="add-border sidebar-item">
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Order Status")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link className=" remove-front d-flex justify-content-between align-items-center"
                     to={`/${user?.role}/current-orders`}
                     onClick={() => {
                      tToggle();
                    }}
                     >
                      
                      {props.t("Current Orders")}{" "}
                      {dispachCount?.current != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.current}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/future-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Future Orders")}
                      {dispachCount?.future != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.future}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/billed-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Billed Orders")}{" "}
                      {dispachCount?.billed != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.billed}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/delivered-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      {props.t("Delivered Orders")}{" "}
                      {dispachCount?.delivered != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.delivered}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/canceled-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Canceled Orders")}
                      {dispachCount?.canceled != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.canceled}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/failed-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Failed Orders")}
                      {dispachCount?.pickupfaild != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.pickupfaild}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/return-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Return Orders")}{" "}
                      {dispachCount?.returnOrders != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.returnOrders}</span>
                      ) : (
                        <span></span>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link className="remove-front d-flex justify-content-between align-items-center"
                      to={`/${user?.role}/reattempt-orders`}
                      onClick={() => {
                        tToggle();
                      }}
                    >
                      
                      {props.t("Reattempt Orders")}{" "}
                      {dispachCount?.reattenpt != 0 ? (
                        <span className="badge bg-primary me-2 ms-3 badge-style count-color"> {dispachCount?.reattenpt}</span>
                      ) : (
                        <span></span>
                      )}{" "}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/address-book`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-book-alt"></i>
                  <span>{props.t("Address Book")}</span>
                </Link>
              </li>

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/invoices`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-credit-card"></i>
                  <span>{props.t("Invoice")}</span>
                </Link>
              </li>
              {/* {user.role === UserTypes.PERSONAL_CUSTOMER && (
                <li>
                  <Link to={`/${user?.role}/draft-orders`} onClick={() => { tToggle(); }}>
                    <i className="bx bx-credit-card"></i>
                    <span>{props.t("Draft Orders")}</span>
                  </Link>
                </li>
              )} */}

              <li className="add-border sidebar-item">
                <Link
                  to={`/${user?.role}/payment-logs`}
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="bx bx-list-ol"></i>
                  <span>{props.t("Payment Logs")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      )}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
// import { Input, DatePicker, Calendar } from 'rsuite';
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import { DriverCommission } from "models/DriverCommission";
import { AdminService } from "services/AdminService";
import DriverInvoiceView from "./DriverInvoiceView";
import { toast } from "react-toastify";
import DriverTableContainer from "./View Billing/DriverTableContainer";

const DriverOldBillingView: React.FC = () => {
  const [startDate, setStartDate] = useState(moment.tz("America/Toronto").subtract(1, "month").startOf("month"));  
  const [endDate, setEndDate] = useState(moment.tz("America/Toronto").startOf('week').subtract(1, 'days'));

  const [showTable, setShowTable] = useState(false);
  const [driverBills, setDriverBills] = useState<DriverCommission[]>();
  const [driverBill, setDriverBill] = useState<DriverCommission>();
  const [LIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const [modal, setModal] = useState(false);
  const lastSunday = moment.tz("America/Toronto").startOf('week').subtract(1, 'days').toDate();

  const data = {
    date1: startDate,
    date2: endDate,
  };

  useEffect(() => {
    AdminService.getAllDriverOldBilling(data, LIMIT_P_C, isOffset_p_c)
      .then(res => {
        if (res.success) {
          setDriverBills(res.data.commisionList);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
          setShowTable(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [showTable]);

  const SeeMore = (page: any) => {
    const count = page.selected + 1;
    setOffset_p_c(page.selected + 1);
    AdminService.getAllDriverOldBilling(data, LIMIT_P_C, count).then(res => {
      if (res.success) {
        setDriverBills(res.data.commisionList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      date1: startDate,
      date2: endDate,
      status: "sent to driver",
      searchableString: serchText,
    };

    AdminService.getDriverBillingBySearch(searchdata).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setDriverBills(res.data);
        }
      }
    });
  };

  const handleStartDate = (date: Date | null) => {
    const sDate = moment(date);
    setStartDate(sDate);
  };

  const handleEndDate = (date: Date | null) => {
    const eDate = moment(date);
    setEndDate(eDate);
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleViewInvoice = (values: any) => {
    setDriverBill(values?.original);
    handlePopup();
  };

  const handleSendEmail = (selectedIds: string[], selectedOtherIds: string[]) => {
    const driverIds = Array.from(new Set(selectedOtherIds));
    driverIds.map(id => {
      const data = {
        commisionIds: selectedIds,
        driverId: id,
      };

      AdminService.sendDriverInvoiceEmail(data).then(res => {
        if (res.success) {
          toast.success("Invoice has been sent Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      });
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "driverId",
        Header: "Driver ID",
        accessor: "driverDetails.customerId",
      },
      {
        id: "driverName",
        Header: "Driver Name",
        accessor: "driverDetails.firstName" || "",
        Cell: ({ row }: any) => {
          return row?.original?.driverDetails?.firstName + " " + row?.original?.driverDetails?.lastName;
        },
      },
      {
        id: "totalOrders",
        Header: "Total Number of Orders",
        accessor: "totalOrders",
        Cell: ({ value }) => `${value.toString().padStart(2, "0")}`,
      },
      {
        id: "totalAmount",
        Header: "Total Order Value",
        accessor: "totalOrderAmount",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        id: "commission",
        Header: "Commission Earned",
        accessor: "totalCommision",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button
                className="btn btn-primary btn-sm px-3"
                onClick={() => {
                  handleViewInvoice(row);
                  setShowTable(!showTable);
                }}
              >
                {" "}
                View Invoice{" "}
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Driver Billing | Zulu Courier";

  return (
    <React.Fragment>
      <div className="page-content mb-5">
        <div className="container-fluid mb-5">
          <Breadcrumb title="Driver Billing" breadcrumbItem="Create Invoice" />
          <Row>
            <Col lg="12">
              <Card className="mb-5">
                <CardBody>
                  <div className="wizard clearfix mb-5">
                    <Label>Date Selection</Label>
                    <Row>
                      <Col lg="4" className="h-100">
                        <DatePicker
                          selected={startDate ? startDate.toDate() : null}
                          onChange={handleStartDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                          maxDate={lastSunday}
                        />
                        {/* <div className="rm-border">
                          <DatePicker

                            format="MM/dd/yyyy hh:mm aa"
                            className="form-control mb-2"
                            hideMinutes={(minute: number) => minute % 15 !== 0}
                            value={startDate ? startDate.toDate() : null}
                            onChange={handleStartDate}
                          />
                        </div> */}

                      </Col>
                      <Col lg="1" className="text-center">
                        <Label className="mt-lg-2">To</Label>
                      </Col>
                      <Col lg="4">
                        {/* <div className="rm-border">
                          <DatePicker
                            value={endDate ? endDate.toDate() : null}
                            format="MM/dd/yyyy hh:mm aa"
                            className="form-control mb-2"
                            hideMinutes={(minute: number) => minute % 15 !== 0}
                            onChange={handleEndDate}
                          />
                        </div> */}
                        <DatePicker
                          selected={endDate ? endDate.toDate() : null}
                          onChange={handleEndDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                           maxDate={lastSunday}
                        />
                      </Col>
                      <Col lg="3">
                        <button type="submit" onClick={() => setShowTable(!showTable)} className="btn btn-primary btn-sm px-3 me-2 mt-lg-2">
                          Filter
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div className="wizard clearfix">
                    <Label>Billing Table</Label>
                    <Row>
                      <Col lg="12">
                        {driverBills !== undefined && driverBills.length > 0 ? (
                          <DriverTableContainer
                            columns={columns}
                            data={driverBills}
                            pagelimit={pageCount}
                            handlePageChange={SeeMore}
                            handleSearch={searchTable}
                            customPageSize={100}
                            className="custom-header-css"
                            hasCheckBox={true}
                            handleSendEmail={handleSendEmail}
                          />
                        ) : (
                          <Row className="justify-content-md-center justify-content-center align-items-center mb-5">
                            <Col className="col-md-auto">
                              <div>No Driver Billings</div>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <DriverInvoiceView isOpen={modal} handlePopup={handlePopup} driverBill={driverBill} dates={data} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverOldBillingView;

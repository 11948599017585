export enum DispatchType {
  LIVE_ORDERS = "live_orders",
  CURRENT_ORDERS = "current_orders",
  BILLED_ORDERS = "billed_orders",
  PICK_UP_ORDERS = "pick_up_orders",
  FAILED_PICK_UP = "failed_pickup",
  FUTURE_ORDERS = "future_orders",
  DELIVERED_ORDERS = "delivered_orders",
  DELIVERED_TODAY = "delivered_today",
  RETURN_ORDERS = "return_orders",
  REATTEMPT_ORDERS = "reattempt_orders",
  CANCELED_ORDERS = "canceled_orders",
  DRAFT_ORDERS = "draft_orders",
}
